<template>
  <div class="page_bg">
    <!-- 顶部返回 -->
    <div>
      <div class="back_top_name flex-r-sb-c" style=" padding:0 0.32rem;background: #ffffff;font-size: 0.48rem;">
        <van-icon name="arrow-left" @click="backPath" />
        <div class="top_name top_ser">
          <van-search v-model="orderSearch.proName" class="classify_ser" placeholder="请输入搜索关键词" shape="round"
                      @search="serOrder"
          />
        </div>
        <div class="top_icon" />
      </div>
    </div>
    <!-- list -->
    <div class="order_list_box">
      <MyscrollList ref="myList01" :loading="listLoading" :list="orderList" :line-type="1" @myload="orderload">
        <template #default="slotProps">
          <div class="order_list_item" @click="jumpDetail(slotProps.item)">
            <!-- 订单单号和状态 -->
            <div class="item_title flex-r-sb-c">
              <div class="item_title_id">售后单号：{{ slotProps.item.refundNo }}</div>
              <div class="item_title_status">
                {{ ['待处理','已通过','已拒绝','已完成','已取消'][slotProps.item.status] }}</div>
            </div>
            <div class="flex-r-sb-c">
              <div v-if="slotProps.item.productList.length === 1" class="item_left_pro flex-r-s-c">
                <div class="left_pro_img">
                  <van-image width="1.92rem" height="1.44rem" :src="slotProps.item.productList[0].proIcon" />
                </div>
                <div class="left_pro_info">
                  <div class="pro_info_name van-multi-ellipsis--l2">{{ slotProps.item.productList[0].proName }}</div>
                  <div class="pro_info_msg van-ellipsis">{{ slotProps.item.productList[0].proBrand }} |
                    {{ slotProps.item.productList[0].proModel }}</div>
                </div>
              </div>
              <div v-else class="item_left_pro">
                <div class="left_pro_img flex-r-c-c ">
                  <van-image v-for="(img,img_index) in slotProps.item.productList" v-show="img_index<3" :key="img_index"
                             width="1.92rem" height="1.44rem" :src="img.proIcon"
                  />
                </div>
              </div>
              <!-- 订单价格和数量 -->
              <div class="item_right_info">
                <div class="right_info_money">￥{{ slotProps.item.refundAmount }}</div>
                <div class="right_info_num">共计{{ slotProps.item.productSumNumber }}件</div>
              </div>
            </div>
          </div>
        </template>
      </MyscrollList>
    </div>
  </div>
</template>

<script>
import './index.scss'
import MyscrollList from '@/components/MyscrollList'
import { refund_refundlist } from '@/api/order'
export default {
  name: 'OrderList',
  components: { MyscrollList },
  data() {
    return {
      serValue: '',
      // 商品列表
      orderSearch: { page: 1, limit: 10, orderId: '' },
      orderList: [],
      listLoading: false
    }
  },
  created() {
    this.getOrderList()
  },
  methods: {
    backPath() { window.history.back() },
    getOrderList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      refund_refundlist(this.orderSearch).then(res => {
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.orderList = this.orderList.concat(res.data.list)
        for (const i in this.orderList) {
          if (this.orderList[i].expireDate) {
            var startDate1 = new Date().getTime()
            var endDate1 = new Date(this.orderList[i].expireDate).getTime()
            this.$set(this.orderList[i], 'time', (endDate1 - startDate1))
            console.log(this.orderList[i].time)
          }
        }
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    orderload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.orderSearch.page = this.orderSearch.page + 1
        this.getOrderList()
        // 加载状态结束
      }, 50)()
    },
    // 搜索订单
    serOrder(e) {
      this.$refs.myList01.load_status_clear()
      this.orderSearch.proName = e
      this.orderSearch.page = 1
      this.orderList = []
      this.getOrderList()
    },
    topChange(index) {
      this.$refs.myList01.load_status_clear()
      this.orderSearch.status = index
      this.orderSearch.page = 1
      this.orderList = []
      this.getOrderList()
    },
    jumpDetail(item) {
      if (item.status < 4) { this.$router.push('/order/returnStatus?refundId=' + item.refundId) }
    }
  }
}
</script>
<style lang="scss">
.top_ser {
  .classify_ser {
    .van-search__content {
      width: 7rem;
      height: 0.85rem;
      background: #ffffff;
      border: 1px solid #0767ab;
      opacity: 1;
      border-radius: 0.48rem;
    }
  }
}
.order_list_top {
  background: #ffffff;
  text-align: center;
  .top_van-col {
    height: 1rem;
    font-size: 0.37rem;
    color: #333333;
  }
  .top_van-col_actived {
    font-weight: bold;
    color: #0767ab;
  }
}
</style>
